<template>
  <div class="profile-wrap">
    <!-- <div class="profile-card" @click="userInfoHandle">
      <div class="name-wrap">
        <div class="name">{{ userInfo.name }}</div>
        <div class="tag" v-if="userInfo.position">{{ userInfo.position }}</div>
      </div>
      <div class="company">{{ userInfo.company_name }} {{ userInfo.department}}</div>
      <div class="mobile" v-if="userInfo.mobile">
        <Icon class="icon" class-prefix="iconfont" name="dianhua1" size="16" color="#D8D8D8" />
        <span>{{ userInfo.mobile }}</span>
      </div>
      <div class="email" :class="{ mobile: !userInfo.mobile}">
        <Icon class="icon" class-prefix="iconfont" name="youxiang" size="14" color="#D8D8D8" />
        <span>{{ userInfo.email }}</span>
      </div>
      <Icon class="profile-icon" name="arrow" color="#999999" size="20" />
    </div> -->
    <div class="feedback" @click="userInfoHandle">
      <div class="feed">
        <div class="icon"></div>
        <div>密码修改</div>
      </div>
      <Icon name="arrow" color="#999999" size="20" />
    </div>
    <div class="feedback" @click="feedbackHnadle">
      <div class="feed">
        <div class="icon"></div>
        <div>反馈</div>
      </div>
      <Icon name="arrow" color="#999999" size="20" />
    </div>
  </div>
</template>

<script>
import { computed } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { Icon } from 'vant'
export default {
  components: {
    Icon
  },
  setup () {
    // document.title = '个人中心'
    const store = useStore()
    const router = useRouter()
    store.dispatch('user/getUserInfo')
    const userInfo = computed(() => {
      return store.state.user.userInfo
    })

    const userInfoHandle = () => {
      router.push('/password')
    }

    const feedbackHnadle = () => {
      router.push('/feedback')
    }

    return {
      userInfo,
      userInfoHandle,
      feedbackHnadle
    }
  }
}
</script>

<style lang="less" scoped>
.profile-wrap {
  padding: 12px 15px;
  .profile-card {
    padding: 32px 10px 0 16px;
    height: 161px;
    background: url('../../assets/profile.png') no-repeat;
    background-size: cover;
    border-radius: 4px;
    position: relative;
    .profile-icon {
      position: absolute;
      right: 10px;
      top: 42px;
    }
    .name-wrap {
      display: flex;
      .name {
        font-size: 18px;
        font-weight: 500;
        color: #203040;
      }
      .tag {
        margin-left: 8px;
        padding: 0 8px;
        border-radius: 14px;
        border: 1px solid #007AFF;
        font-size: 12px;
        color: #007AFF;
        box-sizing: border-box;
        display: flex;
        align-items: center;
      }
    }
    .company {
      margin-top: 8px;
      font-size: 14px;
      font-weight: 400;
      color: #999999;
      line-height: 14px;
    }
    .mobile {
      margin-top: 20px !important;
      font-size: 14px;
      font-weight: 400;
      color: #999999;
      .icon {
        margin-right: 8px;
      }
    }
    .email {
      margin-top: 9px;;
      font-size: 14px;
      font-weight: 400;
      color: #999999;
      .icon {
        margin-right: 8px;
      }
    }
  }
  .feedback {
    padding: 0 10px 0 18px;
    margin-top: 12px;
    height: 48px;
    background: #FFFFFF;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .feed {
      display: flex;
      font-size: 16px;
      font-weight: 400;
      color: #203040;
      .icon {
        margin-top: 1px;
        margin-right: 10px;
        width: 20px;
        height: 20px;
        background: url('../../assets/feedback.png') no-repeat;
        background-size: cover;
      }
    }
  }
}
</style>
